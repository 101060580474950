.text-input-wrapper {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2), 1px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 5px;

  .text-icon-left {
    align-self: center;
    padding: 5px 10px 3px 10px;
    margin-right: 5px;
  }

  .text-input {
    width: 100%;
    border: none;
    margin: 10px 0px;
  }

  .text-sort {
    padding: 5px 10px 3px 10px;
    margin-right: 5px;
    justify-content: center;
    display: flex;
  }

  .icon-style {
      padding: 5px 5px 0px 0px;
      color: #7fbde0;
      cursor: pointer;
  }
}
